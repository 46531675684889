@import "src/vars";

.App {
  text-align: center;

  &-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  &-container {
    padding-top: calc($header-height + $header-margin);
    min-height: calc(100vh - $header-height - $footer-height - $header-margin);

    margin: 0 auto;
    max-width: 960px;
  }
}
