@import "src/vars";

$background-opacity: 0.9;

.welcome {
  &-container {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("https://rouletterie.ru/images/main.jpg");
    background-size: cover;
    background-position: center;
    background-color: rgba(245, 163, 186, 0.4);

    color: $black;
    padding: 115px;
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 10;

    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-header {
    font-size: 38px;
    line-height: 1.2;
    margin-top: 2rem;
    font-weight: bold;
    margin-bottom: 40px;

    max-width: 380px;
  }

  &-subtext {
    font-size: 28px;
    margin-bottom: 2rem;
    max-width: 550px;
    font-weight: 500;
  }

  &-buttons {
    margin-bottom: 2.5rem;
  }

  @media (max-width: $mobile-width) {
    &-container {
      background: linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), url("https://rouletterie.ru/images/welcome.jpg");
      background-size: cover;
      background-position: bottom;
      color: $black;
      padding: 90px 10px;
      margin: 20px auto;
    }

    &-header {
      font-size: 2rem;
      max-width: 260px;
    }

    &-buttons button {
      margin: 0 4px 8px 4px;
    }

    &-button {
      padding: 0.75rem 1.5rem;
      margin: 14px 6px;
    }
  }
}
