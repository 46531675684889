@import "src/vars";

.values-section {

  &__title {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 4px;
  }

  &__content {
    display: flex;
    align-items: center;

    @media (max-width: $mobile-width) {
      flex-direction: column;
    }

    .values-image {
      width: 450px;

      @media (max-width: 900px) {
        width: 350px;
        padding: 0 15px;
        box-sizing: border-box;
      }

      @media (max-width: $mobile-width) {
        width: 94vw;

        img {
          padding: 10px 15px 0 15px;
          box-sizing: border-box;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .values-content {
      flex: 1;
      padding-left: 20px;
      text-align: left;

      h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }

      .values-text {
        padding: 0 4px;
        p {
          font-size: 20px;
          line-height: 1.4;
        }
      }

      @media (max-width: 900px) {
        padding-left: 10px;
      }
      @media (max-width: $mobile-width) {
        padding-left: 0;
      }
    }
  }
}
