@import "src/vars";

.tabs {
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;

    @media (max-width: 601px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.tab-button {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: black;
    border-bottom: 2px solid transparent;

    @media (max-width: 601px) {
        padding: 8px 20px;
        font-size: 18px;
    }
}

.tab-button.active {
    border-bottom: 2px solid black;
}

.tab-content {
    font-size: 20px;

    @media (max-width: 601px) {
        font-size: 18px;
    }
}

