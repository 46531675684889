@import "src/vars";

.header {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    background: white;
    color: $black;
    z-index: 11;
    font-size: 14px;

    border-bottom: 1px solid $gray2;


    &__section {
        display: flex;
        align-items: center;
    }

    &__link {
        transition: color 0.3s ease, border-bottom 0.3s ease;
        font-size: 16px;
        line-height: 16px;

        height: 40px;
        padding: 8px 10px 6px 10px;
        border: 2px solid transparent;
        margin-right: 4px;
        box-sizing: border-box;
        cursor: pointer;

        &--selected {
            background: $gray;
            border-radius: 12px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__links {
        margin-right: 40px;
    }

    &__logo {
        cursor: pointer;
        padding-right: 4px;
    }

    @media only screen and (max-width: $mobile-width) {
        padding: 14px 16px;

        &__logo {
            height: 45px;
        }
    }
}
