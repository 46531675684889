$gray: #F5F0F2;
$gray2: #E5E8EB;
$gray3: #969696;
$black: #171212;

$pink1: #F5A3BA;
$pink2: #ED5C80;
$pink3: #914e62;

$pink4: #FFC0CB;
$gold: #FFD700;
$blue: #000080;
$cream2: #FFFDD0;
$brown: #8B4513;

$white: #eae6ca;
$cream: #ecece7;
$green-pale: #8a9977;
$pink: #f6d2dc;

$header-height: 84px;
$header-margin: 4px;
$footer-height: 80px;

$mobile-width: 670px;
$tablet-width: 1280px;
