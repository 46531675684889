@import "src/vars";

.contacts {
  iframe {
    height: 400px;
  }

  &__title {
    padding: 10px 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &__section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 32px;

    &__icon {
      background: $gray;
      border-radius: 12px;
      height: 46px;
      width: 46px;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 12px;
    }

    &__title {
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 2px;
    }

    &__description {
      text-align: left;
      font-size: 16px;
      color: $pink3;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__advice {
    padding: 0 14px;
  }

  @media (max-width: $mobile-width) {
    &__section {
      flex-direction: column;
      padding: 20px 20px;

      &__icon {
        height: 42px;
        width: 42px;
        margin-right: 10px;
      }

      &__title {
        font-size: 16px;
        text-align: center;
      }

      &__description {
        text-align: center;
        font-size: 14px;
      }
    }
  }
}