@import "src/vars";

.footer {
    width: 100%;

    height: $footer-height;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray2;

    box-sizing: border-box;
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__social {
        padding: 10px 0 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            padding: 0 8px;
        }
    }

    a {
        color: $gray2;
        font-weight: bold;
        text-decoration: none !important;
    }
}