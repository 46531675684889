@import "src/vars";

.breadcrumbs {
    padding: 20px 0 0 20px;
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: left;

    a {
        color: $brown;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    span {
        color: $pink1; /* Серый цвет для разделителей и текущего наименования продукта */
    }

    @media (max-width: 601px) {
        font-size: 12px;
        padding: 10px 0 0 10px;
    }
}
