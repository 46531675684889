@import "src/vars";

@font-face {
  font-family: 'Noteworthy Light';
  font-style: normal;
  font-weight: normal;
  src: local('Noteworthy Light'), url('../public/fonts/Noteworthy-Lt.woff') format('woff');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $black;
  background: white;
  // font-family: 'Lobster', sans-serif;
  // font-family: 'Futura PT Book', Arial, Helvetica, sans-serif;
  // font-family: 'Roboto', sans-serif;
  // font-family: "Cormorant Garamond", serif;
  font-family: 'Noteworthy Light', Arial, serif;

  --swiper-navigation-size: 32px;
  --swiper-navigation-color: $gray;
  --swiper-theme-color: $gray;

  @media (max-width: $mobile-width) {
    --swiper-navigation-size: 24px;
    --swiper-navigation-sides-offset: 0;
  }
}

button {
  font-family: 'Noteworthy Light', Arial, serif;
}

.subtitle {
  font-size: 2rem;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.button-default {
  padding: 12px 24px;
  margin-right: 1rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    filter: brightness(105%);
  }

  &.primary {
    background-color: $pink1;
  }

  &.secondary {
    background-color: $gray;
  }
}

