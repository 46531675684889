@import "src/vars";

.key-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  height: 100%;

  &__content {
    max-width: 55%;
    text-align: left;
    margin-right: 16px;
  }

  &__title {
    font-size: 38px;
  }

  &__description {
    font-size: 18px;
    color: $black;
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__price {
      font-size: 22px;
    }

    &__buy-button {
      padding: 12px 24px;
      background: $pink1;
      border: none;
      border-radius: 0.25rem;
      font-size: 20px;
      cursor: pointer;
      transition: 250ms all;

      &:hover {
        filter: brightness(105%);
      }
    }
  }

  &__image {
    width: 45%;
    vertical-align: middle;
  }

  @media (max-width: $mobile-width) {
    padding: 4px 40px;
    flex-direction: column-reverse;
    box-sizing: border-box;

    &__content {
      max-width: 100%;
      margin-right: 0;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__footer {
      &__price {
        font-size: 20px;
      }
      &__buy-button {
        padding: 10px 16px;
        font-size: 18px;
      }
    }

    &__image {
      width: 100%;
      vertical-align: middle;
    }
  }

}
