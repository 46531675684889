@import "src/vars";

.menu {
  margin: 20px auto;
  max-width: 1200px;

  @media (max-width: $mobile-width) {
    margin: 10px auto;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: $mobile-width) {
      margin: 10px 0;
    }
  }

  &__navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

    @media (max-width: $mobile-width) {
      margin-bottom: 10px;
    }

    &__item {
      padding: 10px 20px;
      margin: 5px;
      border: none;
      background-color: #f0f0f0;
      cursor: pointer;

      @media (max-width: $mobile-width) {
        padding: 8px 12px;
        margin: 4px;
      }

      &.active {
        background-color: $pink;
      }

      &:hover:not(.active) {
        background-color: #e0e0e0;
      }
    }
  }

  &__list {
    display: grid;
    gap: 4px;

    &__card {
      position: relative; // To position overlay correctly
      text-align: center;
      transition: all 0.3s ease;
      padding: 2px;
      cursor: pointer;
      overflow: hidden; // Hide overflow of the blurred image
      border-radius: 3px;

      &--no-image {
        cursor: default;
        pointer-events: none;
      }

      img {
        width: 100%;
        height: 470px;
        object-fit: cover;
        transition: all 0.3s ease; // Blur transition

        @media (max-width: $mobile-width) {
          height: 450px;
        }
      }

      h3 {
        font-size: 22px;
        margin: 5px 0;

        @media (max-width: $mobile-width) {
          margin: 3px 0;
        }
      }

      p {
        font-size: 18px;
        margin: 5px 0;

        @media (max-width: $mobile-width) {
          font-size: 22px;
          margin: 3px 0;
        }
      }

      .description-overlay {
        position: absolute;
        min-height: 250px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 60px));
        opacity: 0;
        color: white;
        text-align: center;
        width: 100%;
        background-color: rgba(139, 69, 19, 0.8);
        padding: 5px;
        box-sizing: border-box;
        border-radius: 3px;

        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        h3 {
          font-size: 26px;
        }

        p {
          font-family: Arial, Helvetica, sans-serif;
          font-style: italic;
          font-size: 18px;
        }
      }

      &:hover {
        img {
          filter: blur(2px);
        }

        .description-overlay {
          opacity: 1;
        }

        .menu__list__text {
          filter: blur(2px);
        }
      }

      @media (max-width: $mobile-width) {
        user-select: none;
        padding-bottom: 15px;
      }
    }

    /* Media Queries for Grid Layout */
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 901px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .menu__list__card {
    position: relative;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
