@import "src/vars";

.key-products {
  padding-bottom: 50px;

  &__title {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 14px;

    div {
      padding-left: 4px;
    }
  }

  .mySwiper {
    height: 450px;

    @media (max-width: $mobile-width) {
      height: 500px;
    }
  }
}