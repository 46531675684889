@import "src/vars";

.product-detail {
  display: flex;
  flex-direction: column;
  text-align: left;

  .product-content {
    max-width: 960px;
    display: flex;
    padding: 20px;

    @media (max-width: 601px) {
      padding: 10px;
      flex-direction: column;
    }

    .product-images {
      flex: 1;
      width: calc(50% - 40px);

      img {
        width: 100%;
        min-height: 470px;
        max-height: 800px;
        object-fit: cover;
        user-select: none;

        @media (max-width: 601px) {
          min-height: 200px;
          max-height: 430px;
          flex-direction: column;
          padding-bottom: 5px;
        }
      }

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          // Center slide contents vertically
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-button-prev, .swiper-button-next {
          color: $pink2;

          &:after {
            font-size: 42px;
            font-weight: bold;
          }
        }

        .swiper-scrollbar {
          background: $brown;

          &-drag {
            background: $pink1;
          }
        }
      }
    }

    .product-info {
      flex: 1;
      padding-left: 40px;
      max-width: 440px;

      @media (max-width: 601px) {
        padding-left: 0;
      }

      &__title {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      &__category {
        color: #666;
        margin-bottom: 20px;

        @media (max-width: 601px) {
          display: none;
        }
      }

      .price {
        font-size: 30px;
        margin-bottom: 20px;

        @media (max-width: 601px) {
          margin-bottom: 10px;
        }
      }

      &__description {
        font-size: 17px;
        margin-bottom: 40px;

        @media (max-width: 601px) {
          margin-bottom: 20px;
        }

        &-title {
          font-size: 24px;
          margin-bottom: 12px;

          @media (max-width: 601px) {
            margin-bottom: 5px;
          }
        }
      }

      &__button {
        margin: 0;

        @media (max-width: 601px) {
          width: calc(100% - 8px);
        }
      }
    }
  }
}
